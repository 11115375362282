import React from 'react';
import './WhatWeOffer.scss';
import FrameLayout from '../../frame_layout/FrameLayout';

function WhatWeOffer() {
  return (
      <FrameLayout>
         <h1>
            What We Offer
         </h1>
      </FrameLayout>
  );
}

export default WhatWeOffer;
